import { RegionModel } from "models/RegionModel";
import Aura from "assets/Aura.png"

export const getRegionsAvailable = (): RegionModel[] => {
    const regions = [
        {
            id: "Auvergne-Rhône-Alpes",
            label: "Auvergne-Rhône-Alpes",
            description: "Découvrez les meilleurs produits locaux de la région Auvergne-Rhône-Alpes.",
            text: "",
            imageUrl: Aura,
            groups: [
                {
                    id: "org_2sfiztf5rKkGLYSzGwfYvsrMqgu",
                    label: "Région",
                    description: "Nos produits en direct des producteurs de la Drôme, de l'Isère et des autres département de la région.",
                    imageUrl: Aura,
                    deliveryDays: [
                        {
                            value: "tuesday",
                            label: "mardi"
                        },
                        {
                            value: "friday",
                            label: "vendredi"
                        }
                    ],
                    deliveryScope: "Lyon et Grenoble"
                },
            ]
        },
        {
            id: "Île-de-France",
            label: "Île-de-France",
            description: "Découvrez les meilleurs produits locaux de la région Île-de-France.",
            text: "",
            imageUrl: "https://zupimages.net/up/24/42/a23v.png",
            groups: [
                {
                    id: "org_2mA6fQMQobyFQFjByLri9x3tTi0",
                    label: "Seine-et-Marne",
                    description: "Dans la belle campagne de Seine-et-Marne, à quelques dizaines de kilomètres de Paris, 12 producteurs cultivent et produisent de nombreux produits biologiques et de qualité.",
                    imageUrl: "https://zupimages.net/up/24/42/a23v.png",
                    deliveryDays: [
                        {
                            value: "tuesday",
                            label: "mardi"
                        },
                        {
                            value: "friday",
                            label: "vendredi"
                        }
                    ],
                    deliveryScope: "Paris et petite couronne"
                },
            ]
        },
        // {
        //     id: "Occitanie",
        //     label: "Occitanie",
        //     description: "Cette région sera disponible courant 2025",
        //     text: "Notre lancement en Occitanie est prévue prochainement : restez à l'écoute !",
        //     imageUrl: Occ,
        //     groups: []
        // }
    ];
    return regions;
};
