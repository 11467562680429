import React, { useEffect, useState } from 'react';
import SimpleProductCard from '../Cards/SimpleProductCard';
import { ProductModel } from 'models/ProductModel';
import ProductDetailModal from '../Modals/ProductDetailModal';
import { useLocation } from 'react-router-dom';

interface ProductListProps {
  products: ProductModel[];
}

const ProductList: React.FC<ProductListProps> = ({ products }) => {
  const [open, setOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState('');
  const location = useLocation()


  useEffect(() => {
    const getQueryParams = (search: string) => {
      return new URLSearchParams(search);
    };
    const searchParams = getQueryParams(location.search).get('produit')

    if (searchParams) {
      setSelectedProduct(searchParams)
      setOpen(true)
    }
  }, [location])
  

  const onClickProduct = (id:string) => {
    setSelectedProduct(id)
    setOpen(true)
  }
  
  return (
    <div className="flex flex-wrap justify-center items-start">
      <ProductDetailModal idProduct={selectedProduct} open={open} onClose={setOpen} />
      {products.map((product, index) => (
        <div key={index} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/6">
          <SimpleProductCard
            image={product.image}
            price={product.price}
            minimum={product.minimum}
            productId={product.productId}
            productName={product.productName}
            unitFormat={product.unitFormat}
            stock={product.stock}
            onClickProduct={onClickProduct}
            taxRate={product.taxRate}
            quantity={product.quantity}
          />
        </div>
      ))}
    </div>
  );
};

export default ProductList