import axios from 'axios';
// Custom data model used in our site
import { ProducerModel } from 'models/ProducerModel';
import { ProductModel } from 'models/ProductModel';
import { OrganizationModel } from 'models/OrganizationModel';
// Data model retrieve from database
import { ProducerModelDB } from 'models/dao/ProducerModelDB';
import { ProductModelDB } from 'models/dao/ProductModelDB';
import { OrganizationModelDB } from 'models/dao/OrganizationModelDB';
import { useAuth } from '@clerk/clerk-react';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASEURL,
});

const useProducersService = () => {
    const { getToken } = useAuth();

    const getAllOrganizations = async (): Promise<OrganizationModel[]> => {
        try {
            // Get all organizations
            const token = await getToken();
            const response = await api.get('/organizations', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // Transform response to array OrganizationModel
            const transformedOrganizations = response.data.records.map((organization: OrganizationModelDB) => ({
                id: organization.id,
                name: organization.name,
                imageUrl: organization.imageUrl,
                members: []
            }));
            return transformedOrganizations;
        } catch (error) {
            throw error;
        }
    };

    const getOrganizationById = async ({ organizationId }: { organizationId: string }): Promise<OrganizationModel> => {
        try {
            // Get organization
            const token = await getToken();
            const response = await api.get(`/organizations/${organizationId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // Transform response to OrganizationModel
            const transformedOrganization = {
                id: response.data.id,
                name: response.data.name,
                imageUrl: response.data.imageUrl,
                members: response.data.members.map((member: ProducerModelDB) => ({
                    id: member.id,
                    emailAddresses: member.emailAddresses,
                    phoneNumbers: member.phoneNumbers,
                    firstName: member.firstName,
                    lastName: member.lastName,
                    imageUrl: member.imageUrl,
                    fullName: member.firstName + " " + member.lastName,
                    factoryName: member.factoryName
                }))
            }

            return transformedOrganization;
        } catch (error) {
            throw error;
        }
    };

    const getOrganizationProducers = async ({ organizationId }: { organizationId: string }): Promise<ProducerModel[]> => {
        try {
            //Get organization
            const token = await getToken();
            const response = await api.get(`/organizations/${organizationId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // Transform response to array ProducerModel
            const transformedProducers = response.data.members.map((member: ProducerModelDB) => ({
                id: member.id,
                emailAddresses: member.emailAddresses,
                phoneNumbers: member.phoneNumbers,
                firstName: member.firstName,
                lastName: member.lastName,
                imageUrl: member.imageUrl,
                fullName: member.firstName + " " + member.lastName,
                factoryName: member.factoryName
            }));
            return transformedProducers;
        } catch (error) {
            throw error;
        }
    };

    const getOrganizationProducts = async ({ organizationId }: { organizationId: string }): Promise<ProductModel[]> => {
        try {
            const token = await getToken();
            const response = await api.get(`/organizations/${organizationId}/products`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // Transform response to array ProductModel
            const transformedProducts = response.data.records.filter((p: ProductModelDB) => p.stock > 0).map((product: ProductModelDB) => ({
                image: product.imageUrl,
                price: product.price,
                minimum: product.sellingUnit,
                productId: product.id,
                productName: product.name,
                stock: product.stock,
                unitFormat: product.unitFormat,
                selected: true,
                userId: product.userId,
                description: product.description,
                category: product.category,
                taxRate: product.taxRate,
                isAvailable: true, // ! Update after backend is fixed
            }));

            return transformedProducts;
        } catch (error) {
            throw error;
        }
    };
    return { getAllOrganizations, getOrganizationById, getOrganizationProducers, getOrganizationProducts };

}

export default useProducersService;